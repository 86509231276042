import { ReactElement } from 'react'
import { ArtistReleaseGroupDescription } from '../../api/types'

import { ArtistVersionTableRender } from './ui'

export type Props = {
    data: NonNullable<ArtistReleaseGroupDescription['keyRelease']>
    view: string
    creditCategory: string
    releaseTypes: string[]
    discogsId: number
    versionCount?: number
    formats: string[] | []
    labels: string[] | []
    countries: string[] | []
    years: number[] | []
    orderByValue: string | undefined
    artistName: string
    anvs: string[]
    search: string
}

export function ArtistViews(props: Props): ReactElement | null {
    const {
        data,
        view,
        creditCategory,
        releaseTypes,
        discogsId,
        versionCount,
        formats,
        labels,
        countries,
        years,
        orderByValue,
        artistName,
        anvs,
        search,
    } = props

    return (
        <ArtistVersionTableRender
            masterData={data}
            view={view}
            versionCount={versionCount}
            formats={formats}
            labels={labels}
            countries={countries}
            years={years}
            creditCategory={creditCategory}
            releaseTypes={releaseTypes}
            artistId={discogsId}
            orderByValue={orderByValue}
            artistName={artistName}
            anvs={anvs}
            search={search}
        />
    )
}
