import { ReactElement, useState } from 'react'
import { Route, match, useHistory } from 'react-router'
import { ApolloError } from '@apollo/client'
import { Meta, Title } from 'react-head'
import { useDiscogsId } from '../../lib/use-discogs-id'
import { Content, createOrder } from '../../lib/components/content'
import { Identifier, IdentifierType } from '../../lib/components/identifier'
import { ErrorMessage } from '../../lib/components/error'
import { languages } from '../../lib/i18n'
import { useLogin } from '../../lib/login'
import loadable from '@loadable/component'
import { Page } from './analytics'
import { useFlag } from '../../lib/flags'

import { ArtistHeader } from '../../components/artist-header'
import {
    useArtistDataQuery,
    useUserArtistDataQuery,
    UserArtistDataQuery,
    ItemType,
    useDeferredArtistListDataQuery,
    useDeferredArtistReviewsDataQuery,
} from '../../api/types'
import { ErrorSection } from '../../lib/components/error-section'
import { Trans } from '@lingui/macro'
import { MarketplaceStats } from '../../components/marketplace-stats'
import { ArtistVideos } from '../../components/artist-videos'
import { ShareButton as ArtistShare } from '../../components/share'
import { ArtistActionsDesktop } from '../../components/artist-actions'
import { CuratedLists } from '../../components/curated-lists'
import { ReleaseReviews as ArtistReviews } from '../../components/reviews'
import { ArtistSEOSchema } from '../../components/seo-schema'
import { ArtistMetaTags } from '../../components/artist-meta-tags'
import { AlternateLinks } from '../../lib/i18n/alt'
import { AdBottom, AdRightB, AdTop } from '../../lib/components/ads'
import { ArtistAdTargeting } from '../../components/artist-ad-targeting'
import { ArtistBanner } from '../../components/artist-banner'
import { ArtistDiscography, UseHistoryStateType } from '../../components/artist-discography'
import { Tabs } from '../../components/tabs'

import css from './styles.css'

const ImageGallery = loadable(() => import('./images'))

type Props = {
    match: match<{
        discogsId: string
        slug?: string
    }>
}

type QueryResult<T> = {
    data: T | undefined
    loading: boolean
    error?: ApolloError
}

const { Main, Sidebar } = createOrder([
    'ad',
    'header',
    'actions',
    'marketplace',
    'reviews',
    'share',
    'lists',
    'videos',
    'optIn',
])

function useUserArtistData(discogsId: number): QueryResult<UserArtistDataQuery> {
    const ssrUser = useFlag('ssr_user')
    const { loggedIn } = useLogin()

    return useUserArtistDataQuery({
        ssr: ssrUser,
        returnPartialData: true,
        errorPolicy: 'all',
        variables: { discogsId },
        skip: !loggedIn,
    })
}

export default function ArtistPage(props: Props): ReactElement {
    const { match } = props
    const discogsId = useDiscogsId(match.params.discogsId)

    if (discogsId === 0) {
        return (
            <Page discogsId={discogsId}>
                <ErrorMessage
                    status={404}
                    message={<Trans>This artist does not exist or may have been deleted.</Trans>}
                />
            </Page>
        )
    }

    return (
        <Page discogsId={discogsId}>
            <ArtistMain {...props} discogsId={discogsId} />
            <Route path={`${match.path}/image/:imageId`} component={ImageGallery} />
        </Page>
    )
}

type MainProps = {
    discogsId: number
}

function ArtistMain(props: MainProps): ReactElement | null {
    const { discogsId } = props
    const [tab, setTab] = useState('Discography')
    const [headerIsExpanded, setHeaderIsExpanded] = useState(false)
    const [artistHasNoReleases, setArtistHasNoReleases] = useState(false)

    const ssrDefer = useFlag('ssr_defer')
    const history = useHistory()
    const { data, loading, error } = useArtistDataQuery({
        ssr: true,
        variables: { discogsId },
        fetchPolicy: process.browser && !process.isDevWatching ? 'cache-only' : 'cache-first',
        errorPolicy: 'all',
    })

    const deferredReviews = useDeferredArtistReviewsDataQuery({
        ssr: ssrDefer,
        returnPartialData: true,
        errorPolicy: 'all',
        variables: { discogsId },
        skip: tab !== 'Reviews',
    })

    const deferredLists = useDeferredArtistListDataQuery({
        ssr: ssrDefer,
        returnPartialData: true,
        errorPolicy: 'all',
        variables: { discogsId },
        skip: tab !== 'Lists',
    })

    const userData = useUserArtistData(discogsId)

    if (!data && !loading && !error) {
        throw new Error('MissingFieldsError')
    }
    const doesNotExist = !loading && data && !data.artist
    if (doesNotExist) {
        return (
            <>
                <ArtistBanner doesNotExist />
                <ErrorMessage
                    status={404}
                    message={<Trans>This artist does not exist or may have been deleted.</Trans>}
                />
            </>
        )
    }

    if (error) {
        throw error
    }

    if (loading) {
        return null
    }

    if (!data?.artist) {
        return null
    }

    const canonical = `https://www.discogs.com${data.artist.siteUrl}`
    const bannerOnlyPage = [194, 355]

    const noIndexList = [617164]

    return bannerOnlyPage.includes(discogsId) || artistHasNoReleases ? (
        <ArtistBanner discogsId={discogsId} doesNotExist={artistHasNoReleases} />
    ) : (
        <>
            <Identifier type={IdentifierType.Artist} discogsId={discogsId} />
            <Title>{`${data.artist.name} Discography: Vinyl, CDs, & More | Discogs`}</Title>
            <ArtistMetaTags {...data.artist} />
            <AlternateLinks languages={languages} host='https://www.discogs.com' />
            {noIndexList.includes(discogsId) && <Meta name='robots' content='noindex' />}
            <Meta name='viewport' content='width=device-width, initial-scale=1' />
            <ArtistSEOSchema {...data.artist} />
            <ArtistAdTargeting {...data.artist} />
            <ArtistBanner historyState={history.location.state as UseHistoryStateType} />
            <Content>
                <Identifier type={IdentifierType.Artist} discogsId={discogsId} />
                {/* MAIN */}
                <Main name='ad'>
                    <ErrorSection>
                        <AdTop />
                    </ErrorSection>
                </Main>
                <Main name='header'>
                    <ErrorSection>
                        <ArtistHeader
                            {...data.artist}
                            setIsHeaderExpanded={setHeaderIsExpanded}
                            altArtist={data.artist.altArtist}
                        />
                    </ErrorSection>
                </Main>

                {/* SIDEBAR */}
                <Sidebar name='actions'>
                    <ErrorSection>
                        <ArtistActionsDesktop discogsId={discogsId} siteUrl={data.artist.siteUrl} />
                    </ErrorSection>
                </Sidebar>
                <Sidebar name='marketplace'>
                    <ErrorSection>
                        <MarketplaceStats
                            itemType={ItemType.Artist}
                            title={data.artist.name}
                            blockedFromSale={false}
                            {...data.artist}
                        />
                    </ErrorSection>
                </Sidebar>
                <Sidebar name='share'>
                    <ErrorSection>
                        <ArtistShare url={canonical} title={data.artist.name} />
                    </ErrorSection>
                </Sidebar>
            </Content>
            {/* TAB BROWSER SYSTEM */}
            <Content>
                <ErrorSection>
                    <Tabs
                        tab={tab}
                        setTab={setTab}
                        artistName={data.artist.name}
                        pendingSubmissions={data.artist.pendingSubmissionsCount}
                        discogsId={discogsId}
                    />
                </ErrorSection>
            </Content>
            {tab === 'Discography' && (
                <Content>
                    <ErrorSection>
                        <ArtistDiscography
                            discogsId={discogsId}
                            altArtist={data.artist.altArtist}
                            name={data.artist.name}
                            tab={tab}
                            setTab={setTab}
                            headerIsExpanded={headerIsExpanded}
                            setArtistHasNoReleases={setArtistHasNoReleases}
                            url={canonical}
                        />
                    </ErrorSection>
                </Content>
            )}
            {tab === 'Reviews' && (
                <Content className={css.artistTabsContainer}>
                    <ErrorSection>
                        <ArtistReviews
                            discogsId={discogsId}
                            {...deferredReviews.data?.artist}
                            itemType={ItemType.Artist}
                        />
                    </ErrorSection>
                </Content>
            )}
            {tab === 'Videos' && (
                <Content className={css.artistTabsContainer}>
                    <ErrorSection>
                        <ArtistVideos {...data.artist} />
                    </ErrorSection>
                </Content>
            )}
            {tab === 'Lists' && (
                <>
                    <Content className={css.artistTabsContainer}>
                        <Main name='lists'>
                            <ErrorSection>
                                <CuratedLists
                                    itemType={ItemType.Artist}
                                    discogsId={discogsId}
                                    user={userData.data?.viewer}
                                    {...deferredLists.data?.artist}
                                />
                            </ErrorSection>
                        </Main>
                    </Content>
                    <Content>
                        <Sidebar>
                            <ErrorSection>
                                <AdRightB />
                            </ErrorSection>
                        </Sidebar>
                    </Content>
                </>
            )}
            <ErrorSection>
                <AdBottom />
            </ErrorSection>
        </>
    )
}
