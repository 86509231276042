import { ReactElement, useRef, useState } from 'react'
import { Trans } from '@lingui/macro'
import { useClickOutside } from '../../lib/use-click-outside'
import css from './styles.css'
import { Edit } from '../../lib/components/icon'
import { Link } from '../../lib/components/link'
import { useInteraction } from '../../lib/components/analytics'

type Props = {
    submissionsPending: number
    artistName: string
    discogsId: number
}

export function ContributorActions(props: Props): ReactElement {
    const { artistName, submissionsPending, discogsId } = props
    const [toggle, setToggle] = useState(false)
    const ref = useRef(null)
    const track = useInteraction()

    function handleAdd() {
        track('Add Release')
    }

    function handleCreate() {
        track('Create Master Release')
    }

    function closePopup() {
        setToggle(false)
    }

    useClickOutside(ref, closePopup)

    return (
        <div className={css.contributorActions} ref={ref}>
            <button onClick={() => setToggle(!toggle)} aria-label='contributor actions' aria-expanded={toggle}>
                <Edit alt='edit icon' aria-hidden='true' />
            </button>
            {toggle && (
                <div className={css.linkContainer}>
                    <span>
                        <Trans>
                            <Link
                                href={`https://www.discogs.com/release/add?from_artist=${artistName}`}
                                onClick={handleAdd}
                            >
                                Add Release
                            </Link>
                        </Trans>
                    </span>
                    <span>
                        <Trans>
                            <Link href='/master/create' onClick={handleCreate}>
                                Create Master Release
                            </Link>
                        </Trans>
                    </span>
                    <span>
                        <Trans>
                            <Link href={`/artist/${discogsId}/submissions`}>
                                {submissionsPending} submissions pending
                            </Link>
                        </Trans>
                    </span>
                </div>
            )}
        </div>
    )
}
