import { ReactElement, useState } from 'react'

import { Link } from '../../lib/components/link'
import { intersperse } from '../../lib/intersperse'
import { ArtistHeaderFragment } from '../../api/types'
import { ShowMore } from '../../lib/components/show-more'

type Props = {
    aliases: NonNullable<ArtistHeaderFragment['aliases']>
    setIsHeaderExpanded: (key: boolean) => void
}

const truncateAt = 20

export function Aliases(props: Props): ReactElement {
    const { aliases, setIsHeaderExpanded } = props
    const [shouldShowMore, setShouldShowMore] = useState(false)
    const truncate = shouldShowMore ? aliases.length : truncateAt
    return (
        <>
            {intersperse(
                ', ',
                aliases.slice(0, truncate).map(
                    (alias) =>
                        alias && (
                            <Link href={alias.siteUrl} key={alias.discogsId}>
                                <span>{alias.name}</span>
                            </Link>
                        ),
                ),
            )}
            <br />
            {aliases.length > truncateAt && (
                <ShowMore
                    isShowingMore={shouldShowMore}
                    onToggle={setShouldShowMore}
                    setIsHeaderExpanded={setIsHeaderExpanded}
                />
            )}
        </>
    )
}
