import { ReactElement } from 'react'
import { Meta, Link } from 'react-head'
import { t } from '@lingui/macro'

import { ArtistMetaTagsFragment, ArtistVisibility } from '../../api/types'
import { useLocalisation } from '../../lib/i18n'

import { canonicalLink } from '../../lib/canonical-link'

export type Props = ArtistMetaTagsFragment

export function ArtistMetaTags(props: Props): ReactElement {
    const { i18n } = useLocalisation()
    const { locale } = i18n

    const { images, siteUrl, name, visibility } = props

    const image = images.edges[0]?.node?.fullsize.sourceUrl

    const ogTitle = name

    const artistTwitterDescription = t(
        i18n,
    )`Explore the discography of ${name}. Shop for vinyl, CDs, and more from ${name} on Discogs.`

    const artistMetaDescription = t(
        i18n,
    )`Explore ${name}'s biography, discography, and artist credits. Shop rare vinyl records, top albums, and more on Discogs.`

    return (
        <>
            {image && <Meta property='og:image' content={image} />}
            <Meta name='description' content={artistMetaDescription} />
            <Meta property='og:site_name' content='Discogs' />
            <Meta property='og:title' content={ogTitle} />
            <Meta property='og:type' content='music.musician' />
            <Meta property='og:url' content={`https://www.discogs.com${siteUrl}`} />
            <Meta property='twitter:card' content='summary_large_image' />
            <Meta property='twitter:site' content='@discogs' />
            <Meta property='twitter:title' content={ogTitle} />
            <Meta property='twitter:description' content={artistTwitterDescription} />
            {image && <Meta property='twitter:image:src' content={image} />}
            <Meta property='og:description' content={artistTwitterDescription} />
            {canonicalLink(locale, siteUrl)}
            <Link rel='alternate' hrefLang='x-default' href={`https://www.discogs.com${siteUrl}`} />

            {visibility !== ArtistVisibility.Visible && <Meta name='robots' content='noindex' />}
        </>
    )
}
