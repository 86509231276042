import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import classnames from 'classnames'
import css from './styles.css'
import { ContributorActions } from '../contributor-actions'

type Props = {
    tab: string
    setTab: (value: string) => void
    pendingSubmissions: number
    artistName: string
    discogsId: number
}

export function Tabs(props: Props): ReactElement {
    const { tab, setTab, artistName, pendingSubmissions, discogsId } = props

    const values = ['Discography', 'Reviews', 'Videos', 'Lists']

    function clickHandler(value: string) {
        setTab(value)
    }

    return (
        <div className={classnames(css.tabsContainer, tab !== values[0] && css.tabsContainerMobile)}>
            <div className={css.innerContainer}>
                {values.map((value) => (
                    <button
                        role='radio'
                        onClick={(e) => clickHandler(value)}
                        className={css.selection}
                        key={value}
                        aria-checked={tab === value}
                    >
                        <Trans>{value}</Trans>
                    </button>
                ))}
            </div>
            <div>
                <ContributorActions
                    artistName={artistName}
                    submissionsPending={pendingSubmissions}
                    discogsId={discogsId}
                />
            </div>
        </div>
    )
}
