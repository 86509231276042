import { useState, useEffect } from 'react'
import { useDataLayer, DataLayerEvent } from '../../lib/data-layer'
import { ArtistAdTargetingFragment } from '../../api/types'

import { useCookie } from '../../lib/cookies'
import { useLogin } from '../../lib/login'

export type ArtistAdTargetingEvent = DataLayerEvent & {
    event: 'ad-targeting'
    ids: string
    artist: string
    siteUrl: string
}

export type Props = ArtistAdTargetingFragment

export function ArtistAdTargeting(props: Props): null {
    const { discogsId, name, siteUrl } = props

    const [targetingGroup, setTargetingGroup] = useCookie('ad-test-group')
    // eslint-disable-next-line react/hook-use-state
    const [group] = useState(targetingGroup ?? (Math.random() < 0.5 ? 'a' : 'b'))
    const { user } = useLogin()

    useEffect(function () {
        if (!targetingGroup) {
            setTargetingGroup(group)
        }
    }, [])

    const artistID = `a${discogsId}`

    const event: ArtistAdTargetingEvent = {
        event: 'ad-targeting',
        itemType: 'ARTIST',
        ids: artistID,
        artist: name,
        siteUrl,
    }

    if (!user) {
        event['ad-targeting-group'] = group
    }

    useDataLayer({ event, ssr: true })

    return null
}
