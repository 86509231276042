import { ReactElement, useState } from 'react'
import { useLocation } from 'react-router'
import { Trans } from '@lingui/macro'
import { Link } from '../../lib/components/link'
import { intersperse } from '../../lib/intersperse'

import css from './style.css'

import { ArtistHeaderFragment } from '../../api/types'
import { ShowMore } from '../../lib/components/show-more'

type Props = {
    name: string
    nameVariations: NonNullable<ArtistHeaderFragment['nameVariations']>
    siteUrl: string
    setIsHeaderExpanded: (key: boolean) => void
}

const truncateAt = 20
// TODO: useQueryParam already existed. Use that with URLSearchParams.
function useQuery() {
    const { search } = useLocation()
    const urlNameVariation = new URLSearchParams(search).get('anv')
    return urlNameVariation
}

export function NameVariations(props: Props): ReactElement {
    const { name, nameVariations, siteUrl, setIsHeaderExpanded } = props
    const [shouldShowMore, setShouldShowMore] = useState(false)
    const truncate = shouldShowMore ? nameVariations.length : truncateAt

    const urlNameVariation = useQuery()

    return (
        <>
            <div className={css.nameVarContainer}>
                {urlNameVariation ? (
                    <Link href={`${siteUrl}`}>
                        <Trans>All</Trans>
                    </Link>
                ) : (
                    <span>
                        <Trans>Viewing All</Trans>
                    </span>
                )}
                {' | '}
                {urlNameVariation === name ? (
                    <p>
                        <Trans>Viewing</Trans> {name}
                    </p>
                ) : (
                    <Link
                        href={`${siteUrl}?anv=${name}&filter_anv=1`}
                        className={urlNameVariation === name ? css.active : ''}
                    >
                        {name}
                    </Link>
                )}
                {urlNameVariation && nameVariations.includes(urlNameVariation) ? (
                    <>
                        {' | '}
                        <p>
                            <Trans>Viewing</Trans> {urlNameVariation}
                        </p>
                    </>
                ) : null}
            </div>
            <div className={css.variations}>
                {intersperse(
                    ', ',
                    nameVariations
                        .slice(0, truncate)
                        .filter((nameVariation) => urlNameVariation !== nameVariation)
                        .map(
                            (name) =>
                                name && (
                                    <Link href={`${siteUrl}?anv=${encodeURIComponent(name)}&filter_anv=1`} key={name}>
                                        {name}
                                    </Link>
                                ),
                        ),
                )}
            </div>
            {nameVariations.length > truncateAt && (
                <ShowMore
                    isShowingMore={shouldShowMore}
                    onToggle={setShouldShowMore}
                    setIsHeaderExpanded={setIsHeaderExpanded}
                />
            )}
        </>
    )
}
