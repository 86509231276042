import { ReactElement, ReactNode } from 'react'
import { Trans } from '@lingui/macro'
import css from './style.css'
import { useQueryParam } from '../../lib/query'
import { Header } from '../../lib/components/header'
import { HeaderTable, Item } from '../../lib/components/header-table'
import { Sites } from '../../lib/components/sites'

import { AltArtistFragment, ArtistHeaderFragment } from '../../api/types'

import { Profile } from '../artist-profile'
import { Groups } from '../artist-groups'
import { NameVariations } from '../name-variations'
import { Members } from '../artist-members'
import { ArtistImages } from '../artist-thumbnail'
import { Aliases } from '../artist-aliases'
import { ArtistActionsMobile } from '../artist-actions'
import { ErrorBoundary } from '../../lib/components/error-boundary'

type Props = ArtistHeaderFragment & {
    setIsHeaderExpanded: (key: boolean) => void
    altArtist: AltArtistFragment['altArtist']
}

export function ArtistHeader(props: Props): ReactElement {
    const {
        name,
        profile,
        urls,
        groups,
        realName,
        nameVariations,
        members,
        siteUrl,
        aliases,
        discogsId,
        setIsHeaderExpanded,
        altArtist,
    } = props
    const [nameVariation] = useQueryParam('anv')

    return (
        <div className={css.artistHeader}>
            <ArtistActionsMobile discogsId={discogsId} siteUrl={siteUrl} />
            <Header title={nameVariation ? nameVariation : name} images={<ArtistImages {...props} />}>
                <HeaderTable>
                    {Boolean(realName) && <Item name={<Trans>Real Name</Trans>}>{realName}</Item>}
                    {Boolean(profile.html) && (
                        <Item name={<Trans>Profile</Trans>}>
                            <ErrorSection>
                                <Profile
                                    profile={profile}
                                    setIsHeaderExpanded={setIsHeaderExpanded}
                                    altArtist={altArtist}
                                />
                            </ErrorSection>
                        </Item>
                    )}
                    {urls.length > 0 && (
                        <Item name={<Trans>Sites</Trans>}>
                            <ErrorSection>
                                <Sites urls={urls} setIsHeaderExpanded={setIsHeaderExpanded} />
                            </ErrorSection>
                        </Item>
                    )}
                    {aliases.length > 0 && (
                        <Item name={<Trans>Aliases</Trans>}>
                            <ErrorSection>
                                <Aliases aliases={aliases} setIsHeaderExpanded={setIsHeaderExpanded} />
                            </ErrorSection>
                        </Item>
                    )}
                    {members.length > 0 && (
                        <Item name={<Trans>Members</Trans>}>
                            <ErrorSection>
                                <Members members={members} setIsHeaderExpanded={setIsHeaderExpanded} />
                            </ErrorSection>
                        </Item>
                    )}
                    {groups.length > 0 && (
                        <Item name={<Trans>In Groups</Trans>}>
                            <ErrorSection>
                                <Groups {...props} setIsHeaderExpanded={setIsHeaderExpanded} />
                            </ErrorSection>
                        </Item>
                    )}
                    {nameVariations.length > 0 && (
                        <Item name={<Trans>Variations</Trans>}>
                            <ErrorSection>
                                <NameVariations
                                    siteUrl={siteUrl}
                                    nameVariations={nameVariations}
                                    name={name}
                                    setIsHeaderExpanded={setIsHeaderExpanded}
                                />
                            </ErrorSection>
                        </Item>
                    )}
                </HeaderTable>
            </Header>
        </div>
    )
}

type ErrorSectionProps = {
    children: ReactNode | ReactNode[]
}

function ErrorSection(props: ErrorSectionProps): ReactElement {
    return <ErrorBoundary fallback={Fallback} {...props} />
}

function Fallback(): ReactElement {
    return <Trans>Something went wrong in this section of the page.</Trans>
}
